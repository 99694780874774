var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"ag-grid-demo"}},[_c('b-overlay',{attrs:{"show":_vm.isLoading,"rounded":"sm"}},[_c('b-card',[_c('div',{staticClass:"d-flex flex-wrap justify-content-between my-0"},[_c('b-row',{staticClass:"w-100 mx-0 px-0",class:{'mt-1': _vm.isMobile}},[_c('b-col',{staticClass:"m-0 p-0",attrs:{"cols":"12","md":"10"}},[_c('b-form-input',{staticClass:"md:mb-0 mr-4",class:{'mb-1': _vm.isMobile, 'mb-0': !_vm.isMobile},attrs:{"placeholder":"Search..."},on:{"input":_vm.updateSearchQuery},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),_c('b-col',{staticClass:"m-0 p-0",attrs:{"cols":"12","md":"2"}},[_c('b-button',{staticClass:"mb-1 md:mb-0 w-100",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.gridApi.exportDataAsCsv()}}},[_vm._v("Export as CSV")])],1)],1)],1),_c('vue-good-table',{attrs:{"mode":"remote","pagination-options":{
          enabled: true,
          perPage: _vm.serverParams.perPage,
          perPageDropdown: [5, 10, 20, 50, 100],
          dropdownAllowAll: false,
        },"select-options":{
          enabled: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: false, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        },"is-loading":_vm.isLoading,"total-rows":_vm.totalRecords,"rows":_vm.records,"columns":_vm.columnDefs,"styleClass":"vgt-table condensed bordered","compactMode":""},on:{"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event},"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange},scopedSlots:_vm._u([{key:"column-filter",fn:function(props){return [(props.column.field === 'created_on')?_c('span',{staticClass:"mt-2"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.showDateRangeFilter(true, props.column.field)}}},[_vm._v(" Click to filter the date ")]),(props.column.field in _vm.serverParams.columnFilters)?_c('b-button',{staticClass:"ml-2",on:{"click":function($event){return _vm.resetDateRangeFilter(true, props.column.field)}}},[_vm._v("Reset")]):_vm._e()],1):_vm._e()]}},{key:"table-row",fn:function(props){return [(props.column.field === 'account_approved')?_c('span',[_c('CellRendererStatus',{attrs:{"params":{account_approved: props.row.account_approved}}})],1):(props.column.field === 'account_approval_status')?_c('span',[_c('CellRendererApprovalStatus',{attrs:{"params":{account_approval_status: props.row.account_approval_status}}})],1):(props.column.field === 'actions')?_c('span',[_c('CellRendererActions',{attrs:{"params":{
                account_approval_status: props.row.account_approval_status,
                email_address: props.row.email_address,
                id: props.row.user_id
              }}})],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)],1),_c('b-modal',{staticClass:"bg-danger",attrs:{"centered":"","hide-footer":"","title":"Choose the desired date range to filter the data","size":"lg"},model:{value:(_vm.dateRangeFilter),callback:function ($$v) {_vm.dateRangeFilter=$$v},expression:"dateRangeFilter"}},[_c('v-date-picker',{attrs:{"mode":"date","masks":_vm.masks,"color":"orange","columns":2,"is-range":"","is-expanded":""},model:{value:(_vm.startEndDate),callback:function ($$v) {_vm.startEndDate=$$v},expression:"startEndDate"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }